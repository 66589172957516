var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.fields, function (field) {
      return _c(
        "div",
        { key: field.id },
        [
          _c("pdf-schema-form-field", {
            attrs: {
              field: _vm.objectField(field),
              "field-value": _vm.formData[field.id],
              "use-hint": _vm.useHint,
              "use-suggestion": false,
              disabled: _vm.isDisabledField(field),
              "show-field-label": false,
              "placeholder-text": field.original_field_label || field.title,
            },
            on: { input: _vm.valueUpdated },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }