<template>
  <div>
    <div v-for="field in fields" :key="field.id">
      <pdf-schema-form-field
        :field="objectField(field)"
        :field-value="formData[field.id]"
        :use-hint="useHint"
        :use-suggestion="false"
        :disabled="isDisabledField(field)"
        :show-field-label="false"
        :placeholder-text="field.original_field_label || field.title"
        @input="valueUpdated"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PdfSchemaFormObjectGroup',
  components: {
    PdfSchemaFormField: () => import('@/components/StagelineV2/schemaForm/pdf/fields/PdfSchemaFormField.vue'),
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    useHint: {
      type: Boolean,
      default: false,
    },
    contextualJurisdiction: null,
  },
  data() {
    return {
      fieldIds: {},
    }
  },
  computed: {
    ...mapGetters('stagelineSchemaForm', [
      'mapFieldIdsToGroupedFieldsParts',
      'isDisabledField',
      'formData',
    ]),
  },
  beforeMount() {
    this.fieldIds = this.mapFieldIdsToGroupedFieldsParts(this.fields)
  },
  methods: {
    valueUpdated() {
      this.$emit('input', this.formData)
    },
    objectField(field) {
      const part = field.data?.parts[0]
      if (part) {
        const nestedProperties = part.split('.')
        let currentField = field

        for (const propName of nestedProperties) {
          currentField = currentField.fields.find(f => f.name === propName) || currentField
        }
        // Merge current GT field with resource field since the GT only has minimal data like type and title
        return Object.assign({}, field, currentField)
      }

      return field
    },
  },
}
</script>
